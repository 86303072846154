"use client";

import Affiliate from "@/components/Affiliate/Affiliate";
import BulletPoint from "@/components/bullet-point";
import { AnimationContainer, MaxWidthWrapper } from "@/components/global";
import PricingCards from "@/components/pricing-cards";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "@/components/ui/accordion";
import { BorderBeam } from "@/components/ui/border-beam";
import { LampContainer } from "@/components/ui/lamp";
import MagicBadge from "@/components/ui/magic-badge";
import MagicCard from "@/components/ui/magic-card";
import { FEATURES } from "@/utils";
import { FAQ } from "@/utils/constants/faq";
import { ArrowRightIcon, Play } from "lucide-react";
import Link from "next/link";
import { Suspense } from "react";

const HomePage = () => {
    return (
        <div className="jlt-overflow-x-hidden jlt-scrollbar-hide jlt-size-full">
            <Suspense fallback={""}>
                <Affiliate />
            </Suspense>
            <MaxWidthWrapper>
                <div className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center jlt-w-full jlt-text-center jlt-bg-gradient-to-t jlt-from-background">
                    <AnimationContainer className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center jlt-w-full jlt-text-center">
                        <button className="group jlt-relative jlt-grid jlt-overflow-hidden jlt-rounded-full jlt-px-4 jlt-py-1 jlt-shadow-[0_1000px_0_0_hsl(0_0%_20%)_inset] jlt-transition-colors jlt-duration-200">
                            <span>
                                <span className="jlt-spark jlt-mask-gradient jlt-absolute jlt-inset-0 jlt-h-[100%] jlt-w-[100%] jlt-animate-flip jlt-overflow-hidden jlt-rounded-full [mask:linear-gradient(white,_transparent_50%)] before:jlt-absolute before:jlt-aspect-square before:jlt-w-[200%] before:jlt-rotate-[-90deg] before:jlt-animate-rotate before:jlt-bg-[conic-gradient(from_0deg,transparent_0_340deg,white_360deg)] before:jlt-content-[''] before:[inset:0_auto_auto_50%] before:[translate:-50%_-15%]" />
                            </span>
                            <span className="jlt-backdrop jlt-absolute jlt-inset-[1px] jlt-rounded-full jlt-bg-neutral-950 jlt-transition-colors jlt-duration-200 group-hover:jlt-bg-neutral-900" />
                            <span className="jlt-h-full jlt-w-full jlt-blur-md jlt-absolute jlt-bottom-0 jlt-inset-x-0 jlt-bg-gradient-to-tr jlt-from-primary/20"></span>
                            <span className="jlt-z-10 jlt-py-0.5 jlt-text-sm jlt-text-neutral-100 jlt-flex jlt-items-center jlt-justify-center jlt-gap-1">
                                <Link href="/pricing">LTD DEALS up to 55% OFF! 🔥</Link>
                                <ArrowRightIcon className="jlt-ml-1 jlt-size-3 jlt-transition-transform jlt-duration-300 jlt-ease-in-out group-hover:jlt-translate-x-0.5" />
                            </span>
                        </button>
                        <h1 className="jlt-text-foreground jlt-text-center jlt-py-6 jlt-font-medium jlt-tracking-normal jlt-text-balance sm:jlt-text-3xl lg:jlt-text-7xl !jlt-leading-[1.15] jlt-w-full jlt-font-heading">
                            Search, Find & Act Fast on <br />
                            WordPress Dashboard
                        </h1>
                        <p className="jlt-mb-12 jlt-text-lg jlt-tracking-tight jlt-text-muted-foreground md:jlt-text-xl jlt-text-balance">
                            WP Spotlight boosts your WordPress productivity by up to 50%.
                            <strong> Instantly search,</strong>
                            <br className="jlt-hidden md:jlt-block" />
                            <span className="jlt-hidden md:jlt-block">
                                <strong>manage, and control everything</strong> with a few
                                keystrokes.
                            </span>
                        </p>
                        <div className="jlt-flex jlt-items-center jlt-gap-4 jlt-flex-wrap">
                            <Link
                                href="https://demo.wpspotlight.pro/"
                                target="_blank"
                                className="jlt-text-sm jlt-text-primary-foreground jlt-font-medium jlt-bg-primary jlt-rounded-md jlt-py-2 jlt-px-4">
                                Live Demo
                            </Link>
                            <Link
                                href="/features"
                                className="jlt-inline-flex jlt-items-center jlt-justify-center jlt-whitespace-nowrap jlt-rounded-md jlt-text-sm jlt-font-medium jlt-ring-offset-background jlt-transition-colors focus-visible:jlt-outline-none focus-visible:jlt-ring-2 focus-visible:jlt-ring-ring focus-visible:jlt-ring-offset-2 disabled:jlt-pointer-events-none disabled:jlt-opacity-50 active:jlt-scale-95 jlt-transition-all jlt-bg-primary jlt-text-primary-foreground hover:jlt-bg-primary/90 jlt-primary jlt-h-9 jlt-px-4 jlt-py-2 jlt-bg-purple-500 hover:jlt-bg-purple-500/80 jlt-text-white">
                                All Features
                            </Link>
                        </div>
                    </AnimationContainer>

                    <AnimationContainer
                        delay={0.2}
                        className="jlt-relative jlt-pt-20 jlt-pb-20 md:jlt-py-32 jlt-px-2 jlt-bg-transparent jlt-w-full">
                        <div className="jlt-absolute md:jlt-top-[10%] jlt-left-1/2 gradient jlt-w-3/4 -jlt-translate-x-1/2 h-1/4 md:jlt-h-1/3 jlt-inset-0 jlt-blur-[5rem] jlt-animate-image-glow"></div>
                        <div className="-jlt-m-2 jlt-rounded-xl jlt-p-2 jlt-ring-1 jlt-ring-inset jlt-ring-foreground/20 lg:-jlt-m-4 lg:jlt-rounded-2xl jlt-bg-opacity-50 jlt-backdrop-blur-3xl">
                            <BorderBeam size={250} duration={12} delay={9} />
                            <video
                                width={1200}
                                height={1200}
                                className="jlt-rounded-md"
                                poster="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/12085339/WP-Spotlight-promo-thumb.webp"
                                muted
                                autoPlay
                                loop>
                                <source
                                    src="https://d1k2c27psfzbiv.cloudfront.net/wp-content/uploads/2024/11/14115842/WP-Spotlight-promo-18s.webm"
                                    type="video/mp4"
                                />
                            </video>
                            <div className="jlt-absolute jlt-top-0 jlt-left-0 jlt-w-[100%] jlt-h-[100%] jlt-flex jlt-items-center jlt-justify-center">
                                <div className="jltwp-spotlight-waves jlt-relative jlt-w-[120px] jlt-h-[120px] jlt-flex jlt-justify-center jlt-items-center">
                                    <button className="jltwp-spotlight-play-btn jlt-relative jlt-flex jlt-items-center jlt-justify-center jlt-h-16 jlt-w-16 jlt-bg-[#7370ff] jlt-rounded-full jlt-z-[9999]">
                                        <Play className="jlt-w-6 jlt-h-6" />
                                    </button>
                                </div>
                            </div>
                        </div>
                    </AnimationContainer>
                </div>
            </MaxWidthWrapper>

            {/* Companies Section */}
            {/* <MaxWidthWrapper>
                <AnimationContainer delay={0.4}>
                    <div className="jlt-py-14">
                        <div className="jlt-mx-auto jlt-px-4 md:jlt-px-8">
                            <h2 className="jlt-text-center jlt-text-sm jlt-font-medium jlt-font-heading jlt-text-neutral-400 jlt-uppercase">
                                Trusted by the best in the industry
                            </h2>
                            <div className="jlt-mt-8">
                                <ul className="jlt-flex jlt-flex-wrap jlt-items-center jlt-gap-x-6 jlt-gap-y-6 md:jlt-gap-x-16 jlt-justify-center">
                                    {COMPANIES.map((company) => (
                                        <li key={company.name}>
                                            <Image
                                                src={company.logo}
                                                alt={company.name}
                                                width={80}
                                                height={80}
                                                quality={100}
                                                className="jlt-w-28 jlt-h-auto"
                                            />
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>
                </AnimationContainer>
            </MaxWidthWrapper> */}

            {/* Features Section */}
            <MaxWidthWrapper className="jlt-py-0">
                <AnimationContainer delay={0.1}>
                    <div className="jlt-flex jlt-flex-col jlt-items-center lg:jlt-items-center jlt-justify-center jlt-w-full jlt-py-8 jlt-mx-auto">
                        <MagicBadge title="Features" />
                        <h2 className="jlt-text-center jlt-text-3xl md:jlt-text-5xl !jlt-leading-[1.1] jlt-font-medium jlt-font-heading jlt-text-foreground jlt-mt-6">
                            Search Faster, Work Smarter, Save Time
                        </h2>
                        <p className="jlt-mt-4 jlt-text-center lg:jlt-text-center jlt-text-lg jlt-text-muted-foreground jlt-max-w-lg">
                            Make WP-Admin Navigation as smooth as butter and save
                            <strong> 5-10 hours of work monthly </strong>
                            with WP Spotlight.
                        </p>
                    </div>
                </AnimationContainer>
                <div className="jlt-grid jlt-grid-cols-1 md:jlt-grid-cols-2 lg:jlt-grid-cols-3 jlt-w-full jlt-py-8 jlt-gap-4 md:jlt-gap-8">
                    {FEATURES.map((features, id) => (
                        <AnimationContainer delay={0.2 * id} key={id}>
                            <MagicCard className="group md:jlt-py-8">
                                <div className="jlt-flex jlt-flex-col jlt-items-start jlt-justify-center jlt-w-full">
                                    <div className="jlt-flex jlt-flex-col jlt-relative jlt-items-start">
                                        <h3 className="jlt-text-[1.3rem] jlt-font-medium jlt-text-foreground">
                                            {features.title}
                                        </h3>
                                        <p className="jlt-mt-2 jlt-text-sm jlt-text-muted-foreground">
                                            {features.description}
                                        </p>
                                        <BulletPoint points={features} />
                                        <Link
                                            href={features.action}
                                            className="jlt-text-sm jlt-text-primary-foreground jlt-font-medium jlt-bg-primary jlt-rounded-md jlt-py-2 jlt-px-3 jlt-mt-5">
                                            {features.btnText}
                                        </Link>
                                    </div>
                                </div>
                            </MagicCard>
                        </AnimationContainer>
                    ))}
                </div>
            </MaxWidthWrapper>

            {/* Pricing Section */}
            <MaxWidthWrapper className="jlt-py-10">
                <AnimationContainer delay={0.1}>
                    <div className="jlt-flex jlt-flex-col jlt-items-center lg:jlt-items-center jlt-justify-center jlt-py-8 jlt-mx-auto jlt-w-full">
                        <MagicBadge title="Pricing" />
                        <h2 className="jlt-text-center lg:jlt-text-center jlt-text-3xl md:jlt-text-5xl !jlt-leading-[1.1] jlt-font-medium jlt-font-heading jlt-text-foreground jlt-mt-6">
                            Choose a plan that works for you
                        </h2>
                        <p className="jlt-mt-4 jlt-text-center lg:jlt-text-center jlt-text-lg jlt-text-muted-foreground jlt-max-w-2xl">
                            Whether managing a single site or a multisite network, WP Spotlight’s
                            flexible plans help you save time and simplify your workflow.
                        </p>
                    </div>
                </AnimationContainer>
                <AnimationContainer delay={0.2}>
                    <PricingCards />
                </AnimationContainer>
            </MaxWidthWrapper>

            {/*FAQ Section */}
            <MaxWidthWrapper className="jlt-py-10">
                <AnimationContainer delay={0.3}>
                    <div className="jlt-w-full">
                        <div className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center jlt-w-full jlt-pt-12">
                            <h2 className="jlt-mt-6 jlt-text-2xl jlt-font-semibold jlt-text-center lg:jlt-text-3xl xl:jlt-text-4xl">
                                Frequently Asked Questions
                            </h2>
                        </div>
                        <div className="jlt-max-w-3xl jlt-mx-auto jlt-w-full jlt-mt-20">
                            <Accordion type="single" collapsible>
                                {FAQ.map((faq) => (
                                    <AccordionItem key={faq.id} value={faq.id}>
                                        <AccordionTrigger>{faq.question}</AccordionTrigger>
                                        <AccordionContent>
                                            <div
                                                className="jlt-leading-6"
                                                dangerouslySetInnerHTML={{
                                                    __html: faq.answer,
                                                }}
                                            />
                                        </AccordionContent>
                                    </AccordionItem>
                                ))}
                            </Accordion>
                        </div>
                    </div>
                </AnimationContainer>
            </MaxWidthWrapper>

            {/* CTA Section */}
            <MaxWidthWrapper className="jlt-mt-20 jlt-max-w-[100vw] jlt-overflow-x-hidden jlt-scrollbar-hide">
                <AnimationContainer delay={0.1}>
                    <LampContainer>
                        <div className="jlt-flex jlt-flex-col jlt-items-center jlt-justify-center jlt-relative jlt-w-full jlt-text-center">
                            <h2 className="jlt-bg-gradient-to-b jlt-from-neutral-200 jlt-to-neutral-400 jlt-py-4 jlt-bg-clip-text jlt-text-center jlt-text-4xl md:jlt-text-7xl !jlt-leading-[1.15] jlt-font-medium jlt-font-heading jlt-tracking-tight jlt-text-transparent jlt-mt-8">
                                {/* Step into the future of link management */}
                                Live Life on the Fast Lane
                            </h2>
                            <p className="jlt-text-muted-foreground jlt-mt-6 jlt-max-w-md jlt-mx-auto">
                                Think fast, search fast, execute fast on your day to day WordPress
                                tasks with WP Spotlight.
                            </p>
                            <div className="jlt-mt-6">
                                <div className="jlt-flex jlt-items-center jlt-gap-4 jlt-flex-wrap">
                                    <Link
                                        href="https://demo.wpspotlight.pro/"
                                        target="_blank"
                                        className="jlt-text-sm jlt-text-primary-foreground jlt-font-medium jlt-bg-primary jlt-rounded-md jlt-py-2 jlt-px-4">
                                        Live Demo
                                    </Link>
                                    <Link
                                        href="/pricing"
                                        className="jlt-inline-flex jlt-items-center jlt-justify-center jlt-whitespace-nowrap jlt-rounded-md jlt-text-sm jlt-font-medium jlt-ring-offset-background jlt-transition-colors focus-visible:jlt-outline-none focus-visible:jlt-ring-2 focus-visible:jlt-ring-ring focus-visible:jlt-ring-offset-2 disabled:jlt-pointer-events-none disabled:jlt-opacity-50 active:jlt-scale-95 jlt-transition-all jlt-bg-primary jlt-text-primary-foreground hover:jlt-bg-primary/90 jlt-primary jlt-h-9 jlt-px-4 jlt-py-2 jlt-bg-purple-500 hover:jlt-bg-purple-500/80 jlt-text-white">
                                        Choose Plan
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </LampContainer>
                </AnimationContainer>
            </MaxWidthWrapper>
        </div>
    );
};

export default HomePage;
