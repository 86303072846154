"use client";

import React from "react";
import { motion } from "framer-motion";
import { cn } from "@/utils";

export const LampContainer = ({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) => {
    return (
        <div
            className={cn(
                "jlt-relative jlt-flex jlt-min-h-screen jlt-flex-col jlt-items-center jlt-justify-center jlt-overflow-hidden jlt-w-full jlt-rounded-md jlt-z-0",
                className
            )}>
            <div className="jlt-relative jlt-flex jlt-w-full jlt-flex-1 jlt-scale-y-125 jlt-items-center jlt-justify-center jlt-isolate jlt-z-0 ">
                <motion.div
                    initial={{ opacity: 0.5, width: "15rem" }}
                    whileInView={{ opacity: 1, width: "30rem" }}
                    transition={{
                        delay: 0.3,
                        duration: 0.8,
                        ease: "easeInOut",
                    }}
                    style={{
                        backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
                    }}
                    className="jlt-absolute jlt-inset-auto jlt-right-1/2 jlt-h-56 jlt-overflow-visible jlt-w-[30rem] jlt-bg-gradient-conic jlt-from-purple-500 jlt-via-transparent jlt-to-transparent jlt-text-white [--conic-position:from_70deg_at_center_top]">
                    <div className="jlt-absolute  jlt-w-[100%] jlt-left-0 jlt-bg-background jlt-h-40 jlt-bottom-0 jlt-z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
                    <div className="jlt-absolute jlt-w-40 jlt-h-[100%] jlt-left-0 jlt-bg-background  jlt-bottom-0 jlt-z-20 [mask-image:linear-gradient(to_right,white,transparent)]" />
                </motion.div>
                <motion.div
                    initial={{ opacity: 0.5, width: "15rem" }}
                    whileInView={{ opacity: 1, width: "30rem" }}
                    transition={{
                        delay: 0.3,
                        duration: 0.8,
                        ease: "easeInOut",
                    }}
                    style={{
                        backgroundImage: `conic-gradient(var(--conic-position), var(--tw-gradient-stops))`,
                    }}
                    className="jlt-absolute jlt-inset-auto jlt-left-1/2 jlt-h-56 jlt-w-[30rem] jlt-bg-gradient-conic jlt-from-transparent jlt-via-transparent jlt-to-purple-500 jlt-text-white [--conic-position:from_290deg_at_center_top]">
                    <div className="jlt-absolute  jlt-w-40 jlt-h-[100%] jlt-right-0 jlt-bg-background  jlt-bottom-0 jlt-z-20 [mask-image:linear-gradient(to_left,white,transparent)]" />
                    <div className="jlt-absolute  jlt-w-[100%] jlt-right-0 jlt-bg-background jlt-h-40 jlt-bottom-0 jlt-z-20 [mask-image:linear-gradient(to_top,white,transparent)]" />
                </motion.div>
                <div className="jlt-absolute jlt-top-1/2 jlt-h-48 jlt-w-full jlt-translate-y-12 jlt-scale-x-150 jlt-bg-background jlt-blur-[8rem]"></div>
                <div className="jlt-absolute jlt-top-1/2 jlt-z-50 jlt-h-48 jlt-w-full jlt-bg-transparent jlt-opacity-10 jlt-backdrop-blur-md"></div>
                <motion.div
                    initial={{ width: "8rem" }}
                    whileInView={{ width: "16rem" }}
                    transition={{
                        delay: 0.3,
                        duration: 0.8,
                        ease: "easeInOut",
                    }}
                    className="jlt-absolute jlt-inset-auto jlt-z-30 jlt-h-36 jlt-w-64 -jlt-translate-y-[6rem] jlt-rounded-full jlt-bg-purple-400 jlt-blur-2xl"></motion.div>
                <motion.div
                    initial={{ width: "15rem" }}
                    whileInView={{ width: "30rem" }}
                    transition={{
                        delay: 0.3,
                        duration: 0.8,
                        ease: "easeInOut",
                    }}
                    className="jlt-absolute jlt-inset-auto jlt-z-50 jlt-h-0.5 jlt-w-[30rem] -jlt-translate-y-[7rem] jlt-bg-purple-400"></motion.div>

                <div className="jlt-absolute jlt-inset-auto jlt-z-40 jlt-h-44 jlt-w-full -jlt-translate-y-[12.5rem] jlt-bg-background "></div>
            </div>

            <div className="jlt-relative jlt-z-50 jlt-flex -jlt-translate-y-80 jlt-flex-col jlt-items-center jlt-px-5">
                {children}
            </div>
        </div>
    );
};
