import React from "react";

interface BulletPointProps {
    points: {
        bulletPoint: Array<{
            point: string;
        }>;
    };
}

const BulletPoint: React.FC<BulletPointProps> = ({ points }) => {
    return (
        <ul style={{ padding: "16px 20px 0 20px", listStyle: "disc" }}>
            {points.bulletPoint.map((item, index) => (
                <li key={index} className="jlt-mt-2 jlt-text-sm jlt-text-muted-foreground">
                    {item.point}
                </li>
            ))}
        </ul>
    );
};

export default BulletPoint;
