"use client";

import * as React from "react";
import * as AccordionPrimitive from "@radix-ui/react-accordion";
import { ChevronDown } from "lucide-react";

import { cn } from "@/utils";

const Accordion = AccordionPrimitive.Root;

const AccordionItem = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Item>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Item>
>(({ className, ...props }, ref) => (
    <AccordionPrimitive.Item
        ref={ref}
        className={cn("jlt-border-b jlt-border-border jlt-w-full", className)}
        {...props}
    />
));
AccordionItem.displayName = "AccordionItem";

const AccordionTrigger = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Trigger>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Trigger>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Header className="jlt-flex">
        <AccordionPrimitive.Trigger
            ref={ref}
            className={cn(
                "jlt-flex jlt-flex-1 jlt-w-full jlt-items-center jlt-justify-between jlt-py-4 jlt-font-medium jlt-transition-all jlt-text-muted-foreground hover:jlt-text-foreground [&[data-state=open]>svg]:jlt-rotate-180",
                className
            )}
            {...props}>
            {children}
            <ChevronDown className="jlt-h-4 jlt-w-4 jlt-shrink- jlt-transition-transform jlt-duration-200" />
        </AccordionPrimitive.Trigger>
    </AccordionPrimitive.Header>
));
AccordionTrigger.displayName = AccordionPrimitive.Trigger.displayName;

const AccordionContent = React.forwardRef<
    React.ElementRef<typeof AccordionPrimitive.Content>,
    React.ComponentPropsWithoutRef<typeof AccordionPrimitive.Content>
>(({ className, children, ...props }, ref) => (
    <AccordionPrimitive.Content
        ref={ref}
        className="jlt-overflow-hidden jlt-w-full jlt-text-sm jlt-transition-all data-[state=closed]:jlt-animate-accordion-up data-[state=open]:jlt-animate-accordion-down"
        {...props}>
        <div className={cn("jlt-pb-4 jlt-pt-0", className)}>{children}</div>
    </AccordionPrimitive.Content>
));

AccordionContent.displayName = AccordionPrimitive.Content.displayName;

export { Accordion, AccordionItem, AccordionTrigger, AccordionContent };
