export const FAQ = [
    {
        id: "item-1",
        question: "Can I test WP Spotlight before purchasing?",
        answer: "Yes! We offer a <a href='https://demo.wpspotlight.pro/' target='_blank' style='color:rgb(168 85 247)'>Live Demo</a> of WP Spotlight, and you can also explore our live demo to experience its features firsthand.",
    },
    {
        id: "item-2",
        question: "Does WP Spotlight work on all WordPress sites?",
        answer: "Yes, WP Spotlight is a versatile site management plugin built for compatibility with most WordPress admin tools and themes.",
    },
    {
        id: "item-3",
        question: "How do I set up key binds?",
        answer: `WP Spotlight’s key bind setup is simple. You can access it through the "Key Binds" feature and assign custom shortcuts for every search and action type.`,
    },
    {
        id: "item-4",
        question: "Do I need technical skills to use WP Spotlight?",
        answer: "Not at all! WP Spotlight is designed for users of all skill levels. Our intuitive interface and easy setup mean you can start using it to optimize your WordPress workflow immediatel",
    },
    {
        id: "item-5",
        question: "Can I remove sites and free up license slots?",
        answer: "Absolutely! You can remove sites from your WP Spotlight account at any time, allowing you to reassign your license slots to new installations as needed. Nothing complicated—just add sites when you need them and remove them when you don’t! Simply visit your account settings to manage your licenses effortlessly.",
    },
    {
        id: "item-6",
        question: "Do staging, development, or local sites use up my site licenses?",
        answer: "No, staging, development, and local sites won’t count toward your license limit. You’re free to set up WP Spotlight on these sites for testing without affecting your available license slots for live sites allowing you to set up WP Spotlight for instant site navigation and backend navigation testing.",
    },
    {
        id: "item-7",
        question: "Does it affect my website performance?",
        answer: "No, the plugin is just one javascript file. No hooks are loaded unless search queries are executed. Also the user will need admin access for it to load on their dashboard or website.",
    },
];
