"use client";

import { cn } from "@/utils";
import React, { useRef, useState } from "react";

interface Props {
    children: React.ReactNode;
    className?: string;
}

const MagicCard = ({ children, className }: Props) => {
    const divRef = useRef<HTMLDivElement>(null);
    const [isFocused, setIsFocused] = useState(false);
    const [position, setPosition] = useState({ x: 0, y: 0 });
    const [opacity, setOpacity] = useState(0);

    const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!divRef.current || isFocused) return;

        const div = divRef.current;
        const rect = div.getBoundingClientRect();

        setPosition({ x: e.clientX - rect.left, y: e.clientY - rect.top });
    };

    const handleFocus = () => {
        setIsFocused(true);
        setOpacity(1);
    };

    const handleBlur = () => {
        setIsFocused(false);
        setOpacity(0);
    };

    const handleMouseEnter = () => {
        setOpacity(1);
    };

    const handleMouseLeave = () => {
        setOpacity(0);
    };

    return (
        <div
            ref={divRef}
            onMouseMove={handleMouseMove}
            onFocus={handleFocus}
            onBlur={handleBlur}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
            className={cn(
                "jlt-relative jlt-max-w-md jlt-overflow-hidden jlt-rounded-xl jlt-border jlt-border-border/60 jlt-bg-gradient-to-r jlt-from-background jlt-to-background/40 jlt-p-4 md:jlt-p-6",
                className
            )}>
            <div
                className="jlt-pointer-events-none jlt-absolute -jlt-inset-px jlt-opacity-0 jlt-transition jlt-duration-300"
                style={{
                    opacity,
                    background: `radial-gradient(500px circle at ${position.x}px ${position.y}px, rgba(168,85,247,.15), transparent 60%)`,
                }}
            />
            {children}
        </div>
    );
};

export default MagicCard;
